import React, { useState, useEffect, useRef, useCallback } from 'react'
import './Row.css'
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import { setVideoInfo, setVideoInfoScreenActive, selectVideo, setSelectedItemIndex } from '../features/videoSlice';
import 'react-multi-carousel/lib/styles.css';



function Row({ title, active, contents }) {
    // const [movies, _setMovies] = useState([])
    const [trailerUrl, setTrailerUrl] = useState("")
    const [selectedTrailer, setSelectedTrailer] = useState("")

    const videoContext = useSelector(selectVideo);
    const carouselRef = useRef("")
    const moviesRef = useRef("");
    const selectedItemRef = useRef("");
    const isMovingRef = useRef(false);
    const dispatch = useDispatch();

    /*
    const setMovies = data => {
        console.log(data)
        moviesRef.current = data;
        _setMovies(data);
    };
    */
    const setSelectedItem = data => {
        console.log(data)
        selectedItemRef.current = data;
        //   _setSelectedItem(data);
        let selectedItemIndex = videoContext.selectedItemIndex
        console.log(selectedItemIndex)
        //     if (data !== selectedItemIndex) {
        dispatch(setSelectedItemIndex({
            selectedItemIndex: data
        }));
        //     }
    };

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }


    const keyDownEventListener = useCallback(async (event) => {
        var keyCode = event.keyCode;
        const currentSlide = carouselRef.current.state.currentSlide;
        console.log(carouselRef.current)
        // console.log(carouselRef.current)
        switch (keyCode) {
            case 417:  //forward
                console.log("0 key pressed");
                if (currentSlide < carouselRef.current.state.slidesToShow) {
                    const nextSlide = currentSlide + 1;
                    console.log(carouselRef.current);
                    carouselRef.current.goToSlide(nextSlide)
                }
                break;
            case 412:  //rewind
                console.log("1 key pressed");
                if (currentSlide > 0) {
                    const prevSlide = carouselRef.current.state.currentSlide - 1;
                    console.log(carouselRef.current);
                    carouselRef.current.goToSlide(prevSlide)
                }
                break;
            case 52: case 37:  //left
                console.log("2 key pressed " + selectedItemRef.current);
                console.log(isMovingRef.current)
                console.log(selectedItemRef.current)
                if (!isMovingRef.current && selectedItemRef.current > 0) {
                    setSelectedItem(selectedItemRef.current - 1);
                    if (currentSlide > 0) {
                        const nextSlide = currentSlide - 1;
                        carouselRef.current.goToSlide(nextSlide)
                    }
                }
                break;
            case 54: case 39:  //right
                console.log("3 key pressed " + selectedItemRef.current);
                console.log(moviesRef.current)
                console.log(carouselRef.current.state.currentSlide)
                console.log(carouselRef.current.state.slidesToShow)
                if (!isMovingRef.current && selectedItemRef.current < carouselRef.current.state.totalItems - 1) {
                    setSelectedItem(selectedItemRef.current + 1);
                    if (selectedItemRef.current > 8 - 1 + currentSlide && carouselRef.current.state.currentSlide < carouselRef.current.state.slidesToShow - 2) {
                        const nextSlide = currentSlide + 1;
                        carouselRef.current.goToSlide(nextSlide)
                    }
                }
                break;
            case 53: case 13: case 415:  //play
                console.log("4 key pressed");
                console.log(moviesRef.current)
                handleClick(moviesRef.current[selectedItemRef.current])
                break;
            /*
        case 57: case 413:  //stop
            console.log("5 key pressed");
            stopVideo()
            break;
            */
            default:
                console.log('unknown key pressed');
        }
    }, []);


    // a snippet of code which runs based on specific condition
    useEffect(() => {
        moviesRef.current = contents;

        // if [], run once when the row loads and don't run it again
        // if [fetchUrl] => whever fetchUrl changes, the code runs again
    }, [contents])


    // a snippet of code which runs based on specific condition
    useEffect(() => {
        console.log(!videoContext.videoInfoScreenActive)
        if (active && !videoContext.videoInfoScreenActive) {
            document.addEventListener('keydown', keyDownEventListener);
            return () => document.removeEventListener('keydown', keyDownEventListener)
        }
    },
        [active, keyDownEventListener, videoContext.videoInfoScreenActive])


    useEffect(() => {
        if (active) {
            console.log("is active")
            let selectedItemIndex = videoContext.selectedItemIndex
            console.log(selectedItemIndex)
            if (selectedItemIndex > carouselRef.current.state.totalItems) {
                setSelectedItem(carouselRef.current.state.totalItems - 1)
            }
            else if (selectedItemIndex > 8 - 1) { //number of items visible
                setSelectedItem(8 - 1)
            }
            else {
                setSelectedItem(selectedItemIndex)
            }
        }
        if (!active) {
            carouselRef.current.goToSlide(0)
        }
    }, [active])


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 20
        },
        desktop: {
            breakpoint: { max: 3000, min: 1000 },
            items: 9
        },
        tablet: {
            breakpoint: { max: 1000, min: 464 },
            items: 7
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 4
        }
    };



    const handleClick = (movie) => {
        setTrailerUrl(movie.MediaSource);
        setSelectedTrailer(movie.Title);
        dispatch(setVideoInfo({
            videoSrc: movie.MediaSource,
            videoName: movie.Title,
            videoDescription: movie.Description,
            image: movie.PosterUrl,
            actors: movie.Actors,
            releaseYear: movie.ReleaseYear

        }));
        dispatch(setVideoInfoScreenActive(true));
        /*
        if (trailerUrl) {
            setTrailerUrl('')
        } else {
            setTrailerUrl('https://storage.googleapis.com/shaka-demo-assets/bbb-dark-truths-hls/hls.m3u8')
        }
        */
    }



    return (
        <div className={`row ${active && "active"}`}>
            {/* title */}
            <h2 className={`row__title ${videoContext.selectedItemIndex === 0 && active && "row__title--selected"}`}>{title}</h2>
            <div >
                <Carousel keyBoardControl={false} ref={carouselRef} swipeable={true} draggable={true} responsive={responsive} transitionDuration={150} containerClass="row__carouselContainer" beforeChange={isMovingRef.current = true}
                    afterChange={setTimeout(() => {
                        isMovingRef.current = false
                    }, 200)}
                >
                    {contents.map((content, index) => (
                        <img
                            key={content.ContentId}
                            onClick={() => handleClick(content)}
                            className={`row__poster row__posterLarge ${index === videoContext.selectedItemIndex && active && "selectedItem"}`}
                            src={content.PosterUrl}
                            alt={content.title} />
                    ))}
                </Carousel>
            </div>


        </div>
    )
}

export default Row
