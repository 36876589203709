/*
IMPROVEMENTS TO DO
1. Better navigation in home screen (without borders, selection should be more visible)
2. Make darker image and white text without background, logos also on the image
3. Overlay display only on button press temporarily
4. Transparent overlay


INSTRUCTIONS

Homescreen: navigation with arrows, press OK key to select
Videoscreen: navigation with arrows, press OK key to select buttons, exit fullscreen with yellow button


*/


import React, { useState, useEffect, useRef, useCallback } from 'react'
import './App.css';
import Row from './components/Row'
import Banner from './components/Banner'
import requests from './requests'
import Nav from './components/Nav'
import axios from './axios'
import { selectVideo } from './features/videoSlice'
import { useSelector } from 'react-redux';

//remove state variable

function App() {
  const [categories, setCategories] = useState([])
  const [contents, setContents] = useState([])
  const [selectedRow, _setSelectedRow] = useState(0);
  const selectedRowRef = useRef(0);
  const numberOfRows = useRef(0);
  const videoContext = useSelector(selectVideo);


  const setSelectedRow = data => {
    console.log(data)
    selectedRowRef.current = data;
    _setSelectedRow(data);
  };

  const keyDownEventListener = useCallback((event) => {
    var keyCode = event.keyCode;
    //    let vidContainer = document.getElementsByClassName('row__player')[0];
    //   let vid = vidContainer.getElementsByTagName('video')[0]
    const height = window.innerHeight;
    const vhPixels = height * 0.9   //scrolling speed
    const vhPixelsArrow = height * 0.2   //scrolling speed when pressing up or down button
    console.log(keyCode)
    switch (keyCode) {
      case 50: case 38:  //up:
        event.preventDefault();
        console.log("6 key pressed");
        window.scrollBy(0, -vhPixelsArrow);
        if (selectedRowRef.current > 0) {
          setSelectedRow(selectedRowRef.current - 1);
        }
        console.log(selectedRowRef.current)
        //  window.scrollBy(0, -vhPixelsArrow);
        break;
      case 56: case 40:  //down:
        event.preventDefault();
        console.log("7 key pressed");
        window.scrollBy(0, vhPixelsArrow);
        if (selectedRowRef.current < numberOfRows.current - 1) {
          setSelectedRow(selectedRowRef.current + 1);
        }
        console.log(selectedRowRef.current)
        //    window.scrollBy(0, vhPixelsArrow);
        break;
      case 49: case 405: case 81:  //yellow:   q
        console.log("8 key pressed");
        window.scrollBy(0, vhPixels);

        /*
        if (vid) {
          if (vid.requestFullscreen) {
            vid.requestFullscreen();
          } else if (vid.mozRequestFullScreen) {
            vid.mozRequestFullScreen();
          } else if (vid.webkitRequestFullscreen) {
            vid.webkitRequestFullscreen();
          } else if (vid.msRequestFullscreen) {
            vid.msRequestFullscreen();
          }
        }
        */
        break;
      case 51: case 406: case 87:  //blue: w
        console.log("9 key pressed");
        window.scrollBy(0, -vhPixels);

        /*
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
        else if (document.mozFullscreenElement) {
          document.mozExitFullscreen();
        }
        else if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
        } else if (document.msFullscreenElement) {
          document.msExitFullscreen();
        }
        /*
        if (document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
          else if (document.mozExitFullscreen) {
            document.mozExitFullscreen();
          }
          else if (document.webkitExitFullscreen) { 
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { 
            document.msExitFullscreen();
          }
        }
        */
        break;
      case 8: case 57: case 461:  //signage back button

      default:
        console.log('unknown key pressed');
    }
  }, []);


  /*
  const ReactVideoPlayer = React.forwardRef((props, ref) => (
    <div ref={ref} className="row__player">
      <div className="row__playerInfo">
        <div className="row__movieName">  Watching {videoContext?.videoInfo?.videoName} </div>
      </div>
      <ReactPlayer url={videoContext?.videoInfo?.videoSrc} playing={true} controls={true} />
    </div>
  ));
  */

  // a snippet of code which runs based on specific condition
  useEffect(() => {
    async function fetchCategories() {
      console.log('fetching categories')
      const request = await axios.get(requests.fetchCategories)
      console.log(request.data)
      numberOfRows.current = request.data.length
      setCategories(request.data);
    }

    async function fetchContents() {
      console.log('fetching contents')
      const request = await axios.get(requests.fetchContents)
      console.log(request.data)
      setContents(request.data);
    }

    fetchCategories();
    fetchContents();

    // if [], run once when the row loads and don't run it again
    // if [fetchUrl] => whever fetchUrl changes, the code runs again
  }, [requests.fetchCategories, requests.fetchContents])




  useEffect(() => {
    if (!videoContext.videoInfoScreenActive) {
      document.addEventListener('keydown', keyDownEventListener);
      return () => document.removeEventListener('keydown', keyDownEventListener)
    }
  },
    [videoContext.videoInfoScreenActive])

  return (
    <div className="app">
      <Nav />
      <div className={`app__rows ${videoContext.videoInfoScreenActive && "app__rows--hidden"}`}>
        {categories.map((category, index) => {
          const filteredContentsByCategory = contents.filter(content => {
            //   console.log(content.CategoryIds)
            return content.CategoryIds.includes(category.CategoryId)
          })
          let contentForTest = [...contents, ...contents, ...contents]   //DELETE AFTER TESTING
          //   console.log(filteredContentsByCategory)
          return (
            <>
              {  /*UNCOMMENT AFTER TESTING
                <Row key={category.CategoryId} contents={filteredContentsByCategory} active={selectedRow === index}
                  title={category.Name}
                  fetchUrl={requests.fetchNetflixOriginals}
                  isLargeRow />
              */  }

              {
                <Row key={category.CategoryId} contents={contentForTest} active={selectedRow === index}
                  title={category.Name}
                />
              }
            </>
          )
        })}
      </div>
      {videoContext.videoInfoScreenActive && <Banner />}


      {/*
      <Row title="Trending Now" fetchUrl={requests.fetchTrending} />
      <Row title="Top Rated" fetchUrl={requests.fetchTopRated} />
      <Row title="Action Movies" fetchUrl={requests.fetchActionMovies} />
      <Row title="Comedy Movies" fetchUrl={requests.fetchComedyMovies} />
      <Row title="Horror Movies" fetchUrl={requests.fetchHorrorMovies} />
      <Row title="Romance Movies" fetchUrl={requests.fetchRomanceMovies} />
      <Row title="Documentaries" fetchUrl={requests.fetchDocumentaries} />
      */}

    </div>
  );
}

export default App;
