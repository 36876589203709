import React from 'react'
import './Nav.css'
import LGLogo from '../img/lg-logo-changed.png';
import EUDSLLogo from '../img/eudsl-logo.jpg';

function Nav() {

    return (

        <div className="nav">
            <img
                className="nav__avatar"
                src={EUDSLLogo}
                alt="EUDSL Logo" />
            <img
                className="nav__logo"
                src={LGLogo}
                alt="LG Logo" />


        </div>
    )
}

export default Nav
