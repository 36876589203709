import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectVideo, setVideoInfoScreenActive } from '../features/videoSlice';
import { isMobile } from "react-device-detect";
import ShakaPlayer from 'shaka-player-react';
import shaka from 'shaka-player';
import 'shaka-player/dist/controls.css';
import './Banner.css'


function Banner() {
    const [videoPlaying, _setVideoPlaying] = useState(false)
    const [videoPaused, setVideoPaused] = useState(false)
    const [showVideoControls, _setShowVideoControls] = useState(false)
    const showVideoControlsRef = useRef(showVideoControls);
    const [currentVideoTime, setCurrentVideoTime] = useState("");
    const [videoDuration, setVideoDuration] = useState("");
    const videoContext = useSelector(selectVideo);
    const dispatch = useDispatch();
    const videoRef = useRef("")
    const btnContainerRef = useRef("")
    const timerRef = useRef();
    //  const controllerRef = useRef("");
    const [selectedItem, _setSelectedItem] = useState(1)
    const selectedItemRef = useRef(selectedItem);
    const videoPlayingRef = useRef(videoPlaying);
    let itemIndex = 0;


    const setSelectedItem = data => {
        selectedItemRef.current = data;
        _setSelectedItem(data);
    };

    const setShowVideoControls = data => {
        showVideoControlsRef.current = data;
        _setShowVideoControls(data);
    };

    const setVideoPlaying = data => {
        videoPlayingRef.current = data;
        _setVideoPlaying(data);
    };


    const ImageBaseUrl = 'https://lgvod.swankmp.net/image?imageUri='

    const touchEventListener = useCallback((event) => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
            timerRef.current = null
        }
        setShowVideoControls(true)
        timerRef.current = setTimeout(() => {
            setShowVideoControls(false)
        }, 10000);

    }, [videoPlaying, showVideoControls]);

    const exitHandler = useCallback((event) => {
        console.log('exit handler triggered')
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            stopVideo()
        }
        //  
    }, []);


    const keyDownEventListener = useCallback((event) => {
        var keyCode = event.keyCode;
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
            timerRef.current = null
        }
        setShowVideoControls(true)
        timerRef.current = setTimeout(() => {
            setShowVideoControls(false)
        }, 10000);

        switch (keyCode) {
            case 52: case 37:  //right
                console.log("2 key pressed " + selectedItemRef.current);
                if (selectedItemRef.current > 0) {
                    setSelectedItem(selectedItemRef.current - 1);
                }
                break;
            case 54: case 39:  //left
                console.log("3 key pressed " + selectedItemRef.current);
                if (selectedItemRef.current < itemIndex - 1) {
                    setSelectedItem(selectedItemRef.current + 1);
                }
                break;
            case 53: case 13: case 415:  //play
                console.log("4 key pressed");
                const selectedBtn = document.getElementsByClassName("banner__btn--active")[0]
                console.log(selectedBtn)
                if (selectedBtn) {
                    selectedBtn.click();
                }
                break;
            case 577: case 405:  //number 9 or yellow button
                console.log("9 key pressed");
                handleExitFullScreen();
                break;
            case 8: case 57: case 461:  //signage back button
                if (!videoPlaying) {
                    handleGoBack()
                } else {
                    const videoElement = videoRef.current.videoElement
                    setVideoPlaying(false);
                    videoElement.pause();
                    videoElement.currentTime = 0;
                }

            /*
        case 57: case 413:  //stop
            console.log("5 key pressed");
            stopVideo()
            break;
            */
            default:
                console.log('unknown key pressed');
        }
    }, [videoPlaying, showVideoControls]);

    useEffect(() => {
        if (videoContext.videoInfoScreenActive) {
            document.addEventListener('keydown', keyDownEventListener);
            document.addEventListener('touchstart', touchEventListener);
            document.addEventListener('click', touchEventListener);
            document.addEventListener('fullscreenchange', exitHandler, false);
            document.addEventListener('mozfullscreenchange', exitHandler, false);
            document.addEventListener('MSFullscreenChange', exitHandler, false);
            document.addEventListener('webkitfullscreenchange', exitHandler, false);
            return () => {
                document.removeEventListener('keydown', keyDownEventListener)
                document.removeEventListener('removeEventListener', touchEventListener);
                document.removeEventListener('click', touchEventListener);
                document.removeEventListener('fullscreenchange', exitHandler, false);
                document.removeEventListener('mozfullscreenchange', exitHandler, false);
                document.removeEventListener('MSFullscreenChange', exitHandler, false);
                document.removeEventListener('webkitfullscreenchange', exitHandler, false);
            }
        }

    },
        [keyDownEventListener, videoContext.videoInfoScreenActive])

    useEffect(() => {
        console.log(videoPlaying)
        if (videoPlaying && !videoPaused) {
            const videoEl = videoRef.current.videoElement
            const timer = setInterval(() => {
                const currentTime = timetoHHMMSS(videoEl.currentTime);
                setCurrentVideoTime(currentTime);
            }, 500);
            return () => {
                clearInterval(timer);
            };
        }
    }, [videoPlaying, videoPaused]);



    const replaceHttpsToHttp = (url) => {
        let replacedURL = url.replace('https://', 'http://');
        console.log(replacedURL)
        return replacedURL;
    }

    const timetoHHMMSS = (time) => {
        var sec_num = parseInt(time, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }


    const stopVideo = async () => {
        try {
            console.log('stopVideo triggered');
            const videoElement = videoRef.current.videoElement
            console.log('is playing')
            videoElement.pause();
            videoElement.currentTime = 0;
            setVideoPlaying(false)
        } catch (e) {
            console.log(e);
        }
    }

    const handlePlayVideo = async () => {
        try {
            const videoElement = videoRef.current.videoElement
            if (videoPlaying) {
                console.log('is playing')
                videoElement.pause();
                videoElement.currentTime = 0;
            }

            if (!videoPlaying) {
                if (isMobile) {
                    console.log('watching from mobile device');
                    handleFullScreen()
                }
            }
            if (!videoPlaying && videoPaused) {
                setVideoPaused(false)
            }
            setVideoPlaying(!videoPlaying)
        } catch (e) {
            console.log(e);
        }
    }

    const handleMetadata = event => {
        const duration = event.currentTarget.duration;
        setVideoDuration(timetoHHMMSS(duration));
    }

    useEffect(() => {
        console.log(videoRef.current)
        if (videoRef.current && videoPlaying) {
            const { player, ui, videoElement } = videoRef.current;
            console.log(ui)
            console.log(videoElement)
            if (player) {

                let licenseServerUrl = "https://wvlsmod.swankmp.net/moddrm_proxy";
                let swankportal = "ce055eef-fd51-43ab-a210-44740d2d9372";
                var startingPositionSeconds = 0;
                console.log(player)
                player.configure({
                    drm: {
                        servers: {
                            "com.widevine.alpha": licenseServerUrl
                        }
                    }
                });

                player.getNetworkingEngine().registerRequestFilter(function (type, request) {
                    if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                        request.headers["SWANKPORTAL"] = swankportal;
                    }
                });


                async function loadAsset() {
                    // Load an asset.
                    try {
                        console.log(videoContext?.videoInfo?.videoSrc)
                        // await player?.load(videoContext?.videoInfo?.videoSrc, startingPositionSeconds);
                        await player?.load(videoContext?.videoInfo?.videoSrc, startingPositionSeconds);
                        // Trigger play.
                        videoElement.play();
                    } catch (e) {
                        console.log(e);
                    }
                }

                loadAsset();
            }
        }

    }, [videoRef.current, videoPlaying]);


    const handleGoBack = () => {
        const videoElement = videoRef.current.videoElement
        setVideoPlaying(false)
        videoElement.pause();
        videoElement.currentTime = 0;
        dispatch(setVideoInfoScreenActive(false));
    }
    const handlePauseVideo = () => {
        const videoElement = videoRef.current.videoElement
        setVideoPaused(!videoPaused)
        if (videoPaused) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    }

    const handleGoForward = () => {
        const videoEl = videoRef.current.videoElement
        videoEl.currentTime = videoEl.currentTime + 10
        // videoRef.current.videoElement.seekTo(currentTime + 10, 'seconds')
    }

    const handleGoBackwards = () => {
        //  const currentTime = videoRef.current.videoElement.getCurrentTime()
        //   videoRef.current.videoElement.seekTo(currentTime - 10, 'seconds')
        const videoEl = videoRef.current.videoElement
        videoEl.currentTime = videoEl.currentTime - 10
    }

    const handleFullScreen = () => {
        const videoElement = videoRef.current.videoElement
        console.log(videoElement)
        if (videoElement) {
            if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
            } else if (videoElement.mozRequestFullScreen) {
                videoElement.mozRequestFullScreen();
            } else if (videoElement.webkitRequestFullscreen) {
                videoElement.webkitRequestFullscreen();
            } else if (videoElement.msRequestFullscreen) {
                videoElement.msRequestFullscreen();
            }
        }
    }

    const handleExitFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        else if (document.mozFullscreenElement) {
            document.mozExitFullscreen();
        }
        else if (document.webkitFullscreenElement) {
            document.webkitExitFullscreen();
        }
        else if (document.msFullscreenElement) {
            document.msExitFullscreen();
        }
    }

    return (
        <div className={`banner`} >
            <div className={`banner__contents ${videoPlaying && "hidden"} `}>
                <h1 className="banner__title">
                    {videoContext?.videoInfo?.videoName}
                </h1>

                <div className="banner__buttons" ref={btnContainerRef} >
                    {!videoPlaying && <button className={`banner__btn ${itemIndex++ === selectedItem && "banner__btn--active"}`} onClick={() => handleGoBack()}> Go Back</button>}
                    {!videoPlaying && <button className={`banner__btn ${itemIndex++ === selectedItem && "banner__btn--active"}`} onClick={() => handlePlayVideo()}>{videoPlaying ? 'Stop' : 'Play'}</button>}
                </div>

                <div className="banner__description ">
                    <p>
                        {videoContext?.videoInfo?.videoDescription}
                    </p>
                    <p>
                        Actors: {videoContext?.videoInfo?.actors.join(', ')}
                    </p>
                    <p>
                        Release Year:  {videoContext?.videoInfo?.releaseYear}
                    </p>
                </div>
            </div>
            <div className={`banner__image ${videoPlaying && "hidden"} `}
                style={{
                    backgroundSize: "cover",
                    backgroundImage: `url("${ImageBaseUrl}${replaceHttpsToHttp(videoContext?.videoInfo?.image)}&width=1920&height=1080")`,
                    backgroundPosition: "cover cover"
                }}
            >
                <div className='banner__imageGradient'>
                </div>
            </div>
            <div className={`banner__video ${!videoPlaying && "hidden"} `}>
                <ShakaPlayer ref={videoRef} volume={100} autoPlay onLoadedMetadata={handleMetadata} />
                {showVideoControls && <div className={`banner__videoOverlay banner__videoOverlay--mobile`}>
                    {videoPlaying && <button className={`banner__btn ${itemIndex++ === selectedItem && "banner__btn--active"}`} onClick={() => handlePlayVideo()}>{videoPlaying ? 'Stop' : 'Play'}</button>}
                    {videoPlaying && <button className={`banner__btn ${itemIndex++ === selectedItem && "banner__btn--active"}`} onClick={() => handlePauseVideo()}>{videoPaused ? 'Resume' : 'Pause'}</button>}
                    {videoPlaying && <button className={`banner__btn ${itemIndex++ === selectedItem && "banner__btn--active"}`} onClick={() => handleGoBackwards()}>{'<<'}</button>}
                    {videoPlaying && <button className={`banner__btn ${itemIndex++ === selectedItem && "banner__btn--active"}`} onClick={() => handleGoForward()}>{'>>'}</button>}
                    <div> <span>{currentVideoTime}{'\u00A0'}/ </span>
                        <span> {'\u00A0'}{videoDuration}</span>
                    </div>
                </div>
                }
            </div>
        </div >
    )
}

export default Banner
